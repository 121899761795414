import { defineMessages } from 'react-intl'

export default defineMessages({
  searchHome: {
    id: 'app.containers.SearchManager.searchHome',
    defaultMessage: 'Search',
  },
  title: {
    id: 'app.containers.SearchManager.title',
    defaultMessage: 'Manage Saved Searches',
  },
  usedInHint: {
    id: 'app.containers.SearchManager.usedInHint',
    defaultMessage: 'Show where this search is used',
  },
  notUsed: {
    id: 'app.containers.SearchManager.notUsed',
    defaultMessage: '(Not Used)',
  },
  usedInOnePlace: {
    id: 'app.containers.SearchManager.usedInOnePlace',
    defaultMessage: 'Used in 1 place',
  },
  usedInNPlaces: {
    id: 'app.containers.SearchManager.usedInOnePlace',
    defaultMessage: 'Used in {count} places',
  },
  customSource: {
    id: 'app.containers.SearchManager.customSource',
    defaultMessage: 'Custom',
  },
  emptyPolicies: {
    id: 'app.containers.SearchManager.emptyPolicies',
    defaultMessage: 'none',
  },
  emptyWidgets: {
    id: 'app.containers.SearchManager.emptyWidgets',
    defaultMessage: 'none',
  },
  searchInfoTitle: {
    id: 'app.containers.SearchManager.searchInfoTitle',
    defaultMessage: 'Solution Cards and Widgets',
  },
  policiesTitle: {
    id: 'app.containers.SearchManager.policiesTitle',
    defaultMessage: 'Control Policies',
  },
})
