import { fromJS } from 'immutable'

import { DUPLICATE_SEARCH, DUPLICATE_SEARCH_SUCCESS } from './constants'
import { handleActions } from 'redux-actions'

export const initialState = fromJS({
  duplicatingSearchId: null,
})

const searchReducer = handleActions(
  {
    [DUPLICATE_SEARCH]: (state, { payload }) =>
      state.set('duplicatingSearchId', payload.id),
    [DUPLICATE_SEARCH_SUCCESS]: state => state.set('duplicatingSearchId', null),
  },
  initialState
)

export default searchReducer
