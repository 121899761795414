import { createAction } from 'redux-actions'

import {
  DUPLICATE_SEARCH,
  DUPLICATE_SEARCH_SUCCESS,
  DELETE_SAVED_SEARCH,
  DELETE_SAVED_SEARCH_SUCCESS,
  DELETE_SEARCH_AND_WIDGETS,
} from './constants'

export const duplicateSearch = createAction(DUPLICATE_SEARCH)
export const duplicateSearchSuccess = createAction(DUPLICATE_SEARCH_SUCCESS)
export const deleteSavedSearch = createAction(DELETE_SAVED_SEARCH)
export const deleteSavedSearchSuccess = createAction(
  DELETE_SAVED_SEARCH_SUCCESS
)
export const deleteSearchAndWidgets = createAction(DELETE_SEARCH_AND_WIDGETS)
