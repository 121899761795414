import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import _ from 'lodash'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { push } from 'connected-react-router'
import { Map, fromJS } from 'immutable'
import qs from 'query-string'
import CreatedByBadge from 'components/CreatedByBadge'
import { SEARCH_VIEWS } from 'containers/Search/constants'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import TextLink from 'components/TextLink'
import BarLoadingAnimation from 'components/BarLoadingAnimation'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import permissionChecker from 'containers/PermissionChecker'
import { SONRAI_ORG_NAME } from 'appConstants'
import { fetchSavedSearchesDetails } from 'containers/SonraiData/actions'
import {
  selectSavedSearchesLoading,
  selectSavedSearchesDetailsLoaded,
  selectSavedSearches,
  selectSonraiUsers,
} from 'containers/SonraiData/selectors'

import SearchInfo from './SearchInfo'
import messages from './messages'
import { duplicateSearch, deleteSavedSearch } from './actions'
import { selectDuplicatingSearchId } from './selectors'
import sagas from './sagas'
import reducer from './reducer'

class SearchManager extends ImmutablePureComponent {
  constructor(props) {
    super(props)

    this.state = {
      viewingSearchId: null,
    }

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflow: 'hidden',
        padding: '1em',
        height: '100%',
        gridRowGap: '0.5em',
      },
    }

    this.gridApi = null
  }

  componentDidMount() {
    this.loadDetailsIfNecessary()
  }

  componentDidUpdate() {
    this.loadDetailsIfNecessary()
  }

  componentWillUnmount() {
    this.gridApi = null
  }

  loadDetailsIfNecessary = () => {
    if (!this.props.savedSearchesDetailsLoaded) {
      if (!this.props.savedSearchesLoading) {
        this.props.fetchSavedSearchesDetails()
      }
    }
  }

  onLoad = ({ api }) => {
    this.gridApi = api
  }

  closeSearchInfo = () => {
    this.setState({
      viewingSearchId: null,
    })
  }

  openSearch = ({ sid }) => {
    this.props.push({
      pathname: '/App/Search',
      search: qs.stringify({
        searchId: sid,
        view: SEARCH_VIEWS.BUILD,
      }),
    })
  }

  getRows = () => {
    return _.orderBy(
      this.props.savedSearches
        .toList()
        .toJS()
        .map(search => {
          const source =
            _.get(search, ['ownedByOrganization', 'items', '0', 'sid']) ===
            SONRAI_ORG_NAME
              ? 'Sonrai'
              : this.props.intl.formatMessage(messages.customSource)

          const comment = Object.values(_.get(search, ['query', 'fields'], {}))
            .flatMap(field => field.comments || [])
            .join(' ')

          return {
            name: search.name,
            usedIn: '',
            createdDate: search.createdDate,
            lastModified: search.lastModified,
            createdBy: search.createdBy,
            createdByUser: this.props.sonraiUsers
              .get(search.createdBy, Map())
              .toJS(),
            source: source,
            srn: search.srn,
            sid: search.sid,
            clone: '',
            delete: '',
            search,
            description: search.description,
            comment,
          }
        }),
      'name'
    )
  }

  onClickDelete = search => {
    this.props.deleteSavedSearch(search)
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay()
    }
  }

  onClickDuplicate = search => {
    this.props.duplicateSearch({
      fields: search.getIn(['query', 'fields']),
      resultViews: search.getIn(['query', 'resultViews']),
      name: search.get('name') + ' (Copy)',
      id: search.get('sid'),
    })

    if (this.gridApi) {
      this.gridApi.showLoadingOverlay()
    }
  }

  render() {
    const rows = this.getRows()

    if (!this.props.savedSearchesDetailsLoaded) {
      return <BarLoadingAnimation />
    }

    return (
      <div style={this.styles.container}>
        <div>
          <Breadcrumb>
            <BreadcrumbItem
              style={{ cursor: 'pointer', color: this.props.theme.primary }}
              onClick={() => this.props.push({ pathname: '/App/Search' })}
            >
              <FormattedMessage {...messages.searchHome} />
            </BreadcrumbItem>
            <BreadcrumbItem>
              <FormattedMessage {...messages.title} />
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <BorderedCard style={this.styles.list}>
          <DataTable
            customGridProps={{
              groupUseEntireRow: true,
              overlayLoadingTemplate:
                '<span class="ag-overlay-loading-center">Working...</span>',
            }}
            onLoad={this.onLoad}
            onDoubleClickRow={this.openSearch}
            data={rows}
            autosize={false}
            customColumnConfig={{
              name: {
                flex: 1,
                minWidth: 200,
              },
              sid: {
                hide: true,
              },
              srn: {
                hide: true,
              },
              search: {
                hide: true,
              },
              comment: {
                hide: true,
              },
              createdBy: {
                minWidth: 200,
                width: 200,
                valueGetter: params => {
                  if (!params.data) {
                    return ''
                  }

                  return params.data.createdByUser.name || 'Sonrai'
                },
                cellRendererFramework: ({ data }) => {
                  if (!data) {
                    return null
                  }
                  return <CreatedByBadge table createdBy={data.createdBy} />
                },
              },
              usedIn: {
                valueGetter: params => {
                  if (!params.data) {
                    return null
                  }

                  const search = params.data.search
                  const usedCount =
                    search.containedByControlPolicy.count +
                    search.containedByWidget.count

                  return usedCount
                },
                cellRendererFramework: params => {
                  if (!params.data) {
                    return null
                  }

                  const search = params.data.search
                  const usedCount =
                    search.containedByControlPolicy.count +
                    search.containedByWidget.count

                  if (usedCount === 0) {
                    return <FormattedMessage {...messages.notUsed} />
                  }

                  return (
                    <TextLink
                      color="primary"
                      title={this.props.intl.formatMessage(messages.usedInHint)}
                      onClick={() =>
                        this.setState({ viewingSearchId: search.sid })
                      }
                    >
                      {usedCount === 1 ? (
                        <FormattedMessage {...messages.usedInOnePlace} />
                      ) : (
                        <FormattedMessage
                          {...messages.usedInNPlaces}
                          values={{ count: usedCount }}
                        />
                      )}
                    </TextLink>
                  )
                },
              },
              createdByUser: {
                hide: true,
              },
              clone: {
                width: 70,
                minWidth: 70,
                aggFunc: null,
                pinned: 'right',
                headerName: '',
                enableRowGroup: false,
                menuTabs: [],
                suppressMenu: true,
                cellRendererFramework: params => {
                  if (!params.data) {
                    return null
                  }

                  const canClone = this.props.userHasPermission({
                    permissionName: 'edit.searches',
                  })
                  const search = fromJS(params.data.search)
                  if (canClone) {
                    return (
                      <BorderlessButton
                        title="Duplicate Search"
                        onClick={() => this.onClickDuplicate(search)}
                      >
                        <Icon fa name="copy" />
                      </BorderlessButton>
                    )
                  } else {
                    return (
                      <span
                        style={{ color: this.props.theme.neutralLight }}
                        title="Insufficient Permissions to Clone Search"
                      >
                        <Icon fa name="copy" />
                      </span>
                    )
                  }
                },
              },
              delete: {
                width: 70,
                minWidth: 70,
                aggFunc: null,
                pinned: 'right',
                headerName: '',
                enableRowGroup: false,
                menuTabs: [],
                suppressMenu: true,
                cellRendererFramework: params => {
                  if (!params.data) {
                    return null
                  }

                  const canDelete = this.props.userHasPermission({
                    permissionName: 'edit.searches',
                    resourceId: params.data.search.resourceId,
                  })
                  if (canDelete) {
                    return (
                      <BorderlessButton
                        title="Delete Search"
                        onClick={() => this.onClickDelete(params.data.search)}
                      >
                        <Icon fa name="trash-alt" />
                      </BorderlessButton>
                    )
                  } else {
                    return (
                      <span
                        style={{ color: this.props.theme.neutralLight }}
                        title="This search is owned by Sonrai and cannot be deleted"
                      >
                        <Icon fa name="lock" />
                      </span>
                    )
                  }
                },
              },
            }}
          />
        </BorderedCard>
        <Modal
          isOpen={this.state.viewingSearchId}
          toggle={this.closeSearchInfo}
        >
          <ModalHeader toggle={this.closeSearchInfo}>
            &quot;
            {this.props.savedSearches
              .get(this.state.viewingSearchId, Map())
              .get('name')}
            &quot; used in places
          </ModalHeader>
          <ModalBody>
            <SearchInfo
              search={this.props.savedSearches.get(
                this.state.viewingSearchId,
                Map()
              )}
            />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

SearchManager.propTypes = {
  canDelete: PropTypes.func.isRequired,
  deleteSavedSearch: PropTypes.func,
  duplicatingSearchId: PropTypes.string,
  duplicateSearch: PropTypes.func,
  fetchSavedSearchesDetails: PropTypes.func.isRequired,
  intl: intlShape,
  onSelectSavedSearch: PropTypes.func,
  push: PropTypes.func.isRequired,
  saveDuplicate: PropTypes.func,
  savedSearches: ImmutablePropTypes.map.isRequired,
  savedSearchesLoading: PropTypes.bool,
  savedSearchesDetailsLoaded: PropTypes.bool,
  sonraiUsers: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  duplicatingSearchId: selectDuplicatingSearchId,
  savedSearches: selectSavedSearches,
  savedSearchesLoading: selectSavedSearchesLoading,
  savedSearchesDetailsLoaded: selectSavedSearchesDetailsLoaded,
  sonraiUsers: selectSonraiUsers,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteSavedSearch,
      duplicateSearch,
      fetchSavedSearchesDetails,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'searchManager', reducer })
const withSaga = injectSaga({ key: 'searchManager', saga: sagas })

export default compose(
  withConnect,
  permissionChecker,
  themeable,
  injectIntl,
  withReducer,
  withSaga
)(SearchManager)
