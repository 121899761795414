import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { FormattedMessage } from 'react-intl'

import messages from './messages'

const SearchInfo = ({ theme, search }) => {
  const styles = {
    header: {
      color: theme.neutralDark,
      fontWeight: 400,
    },
    cardtitle: {
      fontWeight: 400,
    },
  }

  const widgets = search.getIn(['containedByWidget', 'items'], List())

  const widgetsBySolutionCard = widgets
    .reduce((cardMap, widget) => {
      const widgetName = widget.get('title')
      const cardName = widget.getIn(['containedBy', 'items', 0, 'name'])

      return cardMap.has(cardName)
        ? cardMap.update(cardName, cardWidgets => cardWidgets.push(widgetName))
        : cardMap.set(cardName, List([widgetName]))
    }, Map())
    .map(widgetNames => widgetNames.join(', '))

  const policies = search.getIn(['containedByControlPolicy', 'items'], List())

  return (
    <div style={{ fontSize: '1.2em', margin: '0.5em 0' }}>
      <div style={{ marginBottom: '1em' }}>
        <h6 style={styles.header}>
          <FormattedMessage {...messages.searchInfoTitle} /> ({widgets.size})
        </h6>
        <div style={{ paddingLeft: '1em' }}>
          {widgetsBySolutionCard.isEmpty() ? (
            <span style={styles.cardtitle}>
              <FormattedMessage {...messages.emptyWidgets} />
            </span>
          ) : (
            widgetsBySolutionCard
              .map((cardWidgets, cardName) => {
                if (cardName) {
                  return (
                    <div>
                      <strong>{cardName}:</strong> {cardWidgets}
                    </div>
                  )
                }
              })
              .toList()
              .toJS()
          )}
        </div>
      </div>

      <h6 style={styles.header}>
        <FormattedMessage {...messages.policiesTitle} /> ({policies.size})
      </h6>
      <div style={{ paddingLeft: '1em' }}>
        {policies.isEmpty() ? (
          <em>
            <FormattedMessage {...messages.emptyPolicies} />
          </em>
        ) : (
          policies
            .toJS()
            .map(policy => <div key={policy.srn}>{policy.title}</div>)
        )}
      </div>
    </div>
  )
}

SearchInfo.propTypes = {
  search: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
}

export default themeable(SearchInfo)
