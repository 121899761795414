import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import { getClient } from 'apolloClient'
import { QueryBuilder } from 'query-builder'
import gql from 'graphql-tag'
import { Map } from 'immutable'

import { selectQueryTypes } from 'containers/SonraiData/selectors'
import {
  setSavedSearch,
  removeSavedSearch,
} from 'containers/SonraiData/actions'

import { deleteSavedSearchSuccess, duplicateSearchSuccess } from './actions'
import {
  DUPLICATE_SEARCH,
  DELETE_SAVED_SEARCH,
  DELETE_SEARCH_AND_WIDGETS,
} from './constants'

function* duplicateSearch(action) {
  try {
    if (action.payload.fields.isEmpty()) {
      //eslint-disable-next-line no-console
      console.warn('Tried to save empty query; exiting')
      return
    }

    const newQueryName = action.payload.name

    const queryTypes = yield select(selectQueryTypes)

    const queryBuilder = new QueryBuilder({
      query: action.payload.fields,
      resultViews: action.payload.resultViews,
      types: queryTypes,
    })

    const queryRootField = action.payload.fields.find(
      field => !field.get('parentId'),
      null,
      Map()
    )

    const mutateSearchConfig = queryBuilder.buildCreateSavedSearch(
      queryRootField.toJS(), //TODO: update query builder to take just the ID here
      newQueryName
    )
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${mutateSearchConfig.gqlStatement}
      `,
      variables: mutateSearchConfig.variables,
    })

    const savedSearch = results.data.CreateSearch

    yield put(setSavedSearch(savedSearch))
    yield put(duplicateSearchSuccess())
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error when handling SAVE_QUERY', e)
  }
}

function* deleteSavedSearch(action) {
  const search = action.payload

  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`
        mutation deleteSavedSearch{
          DeleteSearch(srn: "${search.srn}")
        }
      `,
    })
    yield put(deleteSavedSearchSuccess(search.sid))
    yield put(removeSavedSearch(search.sid))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Error deleteing saved search ${e}`)
  }
}

function* deleteWidget(id) {
  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`
      mutation deleteWidget{
        DeleteWidget(srn: "${id}")
      }
    `,
    })
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`There was an error deleting widgets from search ${e}`)
  }
}

function* deletePolicy(id) {
  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`
      mutation deletePolicy{
        DeleteControlpolicy(srn: "${id}")
      }
    `,
    })
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`There was an error deleting a policy from search ${e}`)
  }
}

function* deleteSearchAndWidgets(action) {
  const search = action.payload.toJS()
  const widgets = search.containedByWidget.items
  const policies = search.containedByControlPolicy.items

  try {
    yield all(widgets.map(widget => call(deleteWidget, widget.srn)))
    yield all(policies.map(policy => call(deletePolicy, policy.srn)))
    yield call(deleteSavedSearch, action)
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error when handling DELETE_SAVED_SEARCH', e)
  }
}

function* searchSaga() {
  yield all([
    takeLatest(DUPLICATE_SEARCH, duplicateSearch),
    takeLatest(DELETE_SAVED_SEARCH, deleteSavedSearch),
    takeLatest(DELETE_SEARCH_AND_WIDGETS, deleteSearchAndWidgets),
  ])
}

export default searchSaga
